import React from "react"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import { Card as CardComponent, css } from 'theme-ui'
import Seo from '@widgets/Seo'
import Divider from '@components/Divider'
import TyrePatternCompact from './TyrePatternCompact'
import PageTitle from '@components/PageTitle'
import { Layout, Stack, Main, Sidebar } from '@layout'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import SizeList from "@src/SizeList"
import getImageVariant from '@components/utils/getImageVariant'

const ExternalLink = props => {
  if (props.href.includes('alupriser.dk') || props.href[0] === '/' || props.href[0] === '#') {
    return <a href={props.href}>{props.children}</a>
  }
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )
}

const components = {
  a: ExternalLink,
  tyresizes: SizeList
}

export default function PatternTemplate({
                                       data: page,
                                       brand,
                                       ...props
                                     }) {

  const { pageContext: { siteUrl } = {} } = props
  const image = props.pageContext.image && getImageVariant(props.pageContext.image, "regular")

  return (
    <Layout {...props}>
      <Seo {...props} description={props.pageContext.short} title={props.pageContext.brand+" "+props.pageContext.pattern} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header={props.pageContext.brand+" "+props.pageContext.pattern}  />
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            <MDXProvider components={components}>
              <MDXRenderer>{props.pageContext.body}</MDXRenderer>
            </MDXProvider>
          </CardComponent>
        </Main>
        <Sidebar>
          <TyrePatternCompact brand={props.pageContext.brand} pattern={props.pageContext.pattern} image={props.pageContext.image} omitTitle/>
        </Sidebar>
      </Stack>
    </Layout>
  )
}
